import "remixicon/fonts/remixicon.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = true
import { toast, ToastContainer }  from 'react-nextjs-toast'

import "../styles/css.css"
import "../styles/drop.css"
import "../styles/all.css"
import "../styles/home.css"
import "../styles/owl.css"
import "../styles/owl.carousel.min.css"
import "../styles/bootstrap.min.css"
import "../styles/core.css"
import "../styles/style.css"

import "../styles/main/nice.select.css"
// import "../styles/main/common.css"
import "../styles/main/home.css"
// import "../styles/main/jquery.toast.css"
import "../styles/main/bootstrap-multiselect.css"
import "../styles/main/selectstyle.css"
import "../styles/main/custom-css.css"

import TagManager from "react-gtm-module"
import Head from "next/head"

/* New i18n -----> Start */
import "../i18n"
/* New i18n -----> End */

import { Provider } from "react-redux"
import { store } from "../redux/storeConfig/store"
import React, { useEffect, useState } from "react"
import { lightTheme, darkTheme, GlobalStyles } from "../assets/ThemeConfig"
import { useRouter } from "next/router"
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from "@apollo/client"
import { useTranslation, useLanguageQuery } from "next-export-i18n"
import { ThemeProvider } from "styled-components";
import Script from "next/script"
/* New Loading -----> Start */
function Loading() {
  const router = useRouter()
  const [query] = useLanguageQuery()
  const { t } = useTranslation()
  const currentLocale = router?.query?.lang ? router?.query?.lang : null
  const changeLocale =
    router?.query?.lang === "en" ||
    router?.query?.lang === "ar" ||
    query?.lang === "en" ||
    query?.lang === "ar"
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleStart = (url) => {
      const newUrl = changeLocale ? url?.length > 0 && url.slice(0, -8) : url
      const asPath = router.asPath.slice(0, -8)
      if (newUrl !== asPath || newUrl !== router.asPath) {
        setLoading(true)
      }
    }
    const handleComplete = (url) => {
      const newUrl = changeLocale ? url?.length > 0 && url.slice(0, -8) : url
      const asPath = router.asPath.slice(0, -8)
      if (newUrl === asPath || newUrl === router.asPath) {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
      else {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
    }
    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleComplete)
    router.events.on("routeChangeError", handleComplete)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleComplete)
      router.events.off("routeChangeError", handleComplete)
    }
  })

  return (
    loading && (
      <div className="spinner14-wrapper">
        <div className="spinner14"/>
      </div>
    )
  )
}


function MyApp({ Component, pageProps }) {
  /* New i18n -----> Start */

  const [theme, setTheme] = useState(lightTheme)

  const toggleTheme = () => {
    theme === 'light' ? setTheme(darkTheme ) : setTheme(lightTheme)
  }


  /* New i18n -----> End */

  //<------------------------------------------------------------------>//
  // const typeDefs = gql``
  const client = new ApolloClient({
    uri: `${process.env.NEXT_PUBLIC_GRAPHQL_BACKEND_URL}`,
    cache: new InMemoryCache()
    // typeDefs
  })
  //<------------------------------------------------------------------>//
  // ----> Start ----> Initialization Google Analytics into Google Tag Manager
  const gtmId = process.env.NEXT_PUBLIC_NEXT_PUBLIC_GTM || ""
  // const tagManagerArgs = (TagManagerArgs = {
  //   gtmId
  // })
  const [text, setText] = useState(false)
  useEffect(() => {
    TagManager.initialize({ gtmId })
  }, [])

  // ----> End ---> Of  Initialization
  let userLocaleStorage
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      //  gtag.pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    router.events.on("hashChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      router.events.off("hashChangeComplete", handleRouteChange)
    }
  }, [router.events])
  const { defaultLocale, locale } = router
  const currentLocale = router?.locale || "en"

  useEffect(() => {
    const dir = currentLocale === "ar" ? "rtl" : "ltr"
    document.querySelector("html").setAttribute("dir", dir)
  }, [locale])
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Head>
          <title>Wholesale Market For B2B Suppliers, Wholesalers in Dubai, UAE - Abraa</title>
          <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"/>
          <Script type="module" src="../next.config.js"/>
          <script src="https://js.upload.io/react-uploader/v3"/>
        </Head>
        <Loading />
          <ThemeProvider theme={theme}>
        <Component {...pageProps} />
            <ToastContainer className="mt-30" align={"right"} position={"top"}/>
          </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default MyApp


